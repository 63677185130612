import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable()
export class TranslateService {

  constructor(
    private app: AppService
  ) { }

  /**
   * 
   * 
   * 
   */
  getValueParams(value,params,campo){
    
    let lg     = this.app.getLocale();
    let c      = campo+"_"+lg;

    if(params[c]){
       return params[c];
    }
    
    return value;
        
  }
  /**
   * 
   * 
   * 
   */
  getValue(value){

    let lg     = this.app.getLocale();
    let config = this.config(); 

    if(config[lg]){
      if(config[lg][value]){
        return config[lg][value];
      }
    }
    return value;

  }
  /**
   * 
   * Configuração das linguagens
   * 
   */
  config(){

    return{
      "en":{
        "DESTINOS": "DESTINATIONS",
        "MONTE SEU PACOTE": "PLAN YOUR TRIP",
        "INFORMAÇÕES ESSENCIAIS": "KEY INFORMATION",
        "CONTATO": "CONTACT",
        "Português": "Portuguese",
        "Inglês": "English",
        "Saúde": "Health",
        "Segurança": "Security",
        "Visto e Documentação": "Documentation",
        "Números de Emergência": "Emergency Numbers",
        "Força e Eletricidade": "Force and Electricity",
        "Clima": "Weather",
        "Telecomunicações": "Telecommunications",
        "Idioma": "Language",
        "Links Úteis": "Important Links",
        "Telefone": "Phone",
        "Enviar": "Submit",
        "ENVIAR": "Submit",
        "nome": "name",
        "telefone": "phone",
        "Hospedagem": "Accommodation",
        "hospedagem": "accommodation",
        "Aéreo": "Flights",
        "Passeios": "Tours",
        "PASSEIOS": "TOURS",
        "Monte seu pacote": "Plan your trip ",
        "Enviando": "Sending",
        "Nos envie uma mensagem": "Send us a message",
        "Nome": "Name",
        "Assunto": "Subject",
        "assunto": "subject",
        "Mensagem": "Message",
        "Contato": "Contact",
        "contato": "contact",
        "Buscando destinos": "Looking for destinations",
        "Atrações": "Attractions",
        "Imperdíveis": "Unmissable",
        "pacote": "package",
        "Monte seu": "Plan your",
        "destinos": "destinations",
        "Contate a": "Contact the",
        "Brocker Eventos": "Brocker Events",
        "e-mail@email.com.br": "Enter your email address.",
        "Experiências":"Experiences",
        "o-evento": "the-event",
        "Veja sobre o Evento": "See about the Event",
        "O EVENTO": "THE EVENT",
        "HOSPEDAGEM E TURISMO": "ACCOMMODATION AND TOURISM",
        "Em breve teremos informações.": "Soon we will have information.",
        "dias": "days",
        "+ DETALHES DO HOTEL": "+ HOTEL DETAILS",
        "HOTEL/CATEGORIA": "HOTEL/CATEGORY",
        "QUARTO/TOTAL": "RATES PER ROOM",
        "FECHAR": "CLOSE",
        "Selecione": "Select",
        "SELECIONAR": "SELECT",
        "SELECIONADO": "SELECTED",
        "Próximo": "Next",
        "PRÓXIMO": "NEXT",
        "Voltar": "Come Back",
        "Finalizar Pedido": "Finish",
        "Nome*": "Name*",
        "telefone*": "telephone*", 
        "nome impresso no cartão de crédito*": "name printed on credit card*",
        "número do cartão de crédito*": "credit card number*",
        "validade do cartão*": "card expiring date*",
        "código de segurança*": "security code*",
        "número de parcelas*": "number of installments*",
        "Diária:": "Daily:",
        "dia": "day",
        "ALTERAR": "UPDATE",
        "Traslado": "Transfer",
        "por veículo": "per car",
        "por pessoa": "per person",
        "PASSEIOS E PASSAGENS AÉREAS": "TOURS AND AIR TRIPS",
        "PASSEIOS/INGRESSOS": "TOUR/TICKETS",
        "PASSAGENS AÉREAS": "AIRLINE TICKETS",
        "TOTAL DO PAGAMENTO": "TOTAL PAYMENT",
        "Bandeira": "Flag",
        "CÓDIGO VOO": "CODE FLIGHT",
        "DATA": "DATE",
        "HORA": "HOUR",
        "Qualquer dúvida, entre em contato direto:": "Any questions, contact us directly:",
        "Gostaria de receber informações sobre passagens aéreas para Porto Alegre ou PASSEIOS E passeios, ingressos com o melhor conforto e custo benefício para você." : "I would like to receive information about airline tickets, tours in Porto Alegre and another places in Brazil with the best prices.",
        "HOSPEDAGEM": "HOTEL RESERVATION",
        "Distância do evento": "Event distance",
        "A partir de:": "From:",
        "COMPRAR": "BUY",
        "Enviando os dados. Aguarde!": "Sending the data. Wait!",
        "CONSULTA DE EXPERIÊNCIA": "EXPERIENCE CONSULTATION",
        "CONSULTE": "CONSULT",
        "SOBRE O EVENTO": "ABOUT THE EVENT",
        "VALORES": "VALUES",
        "DADOS ENVIADOS COM SUCESSO": "DATA SUCCESSFULLY SENT",
        "Recebemos os seus dados. Em breve entraremos em contato!": "We have received your data. Soon we will contact you!",
        "Não temos serviços para mostrar.": "We have no services to show.",
        "ENCONTRE SEU EVENTO IDEAL": "FIND YOUR IDEAL EVENT",
        "CLIQUE AQUI": "CLICK HERE",
        "VER MAIS EVENTOS": "SEE MORE EVENTS",
        "Queremos te ajudar. O que você procura?": "We want to help you. What are you looking for ?",
        "EVENTOS": "EVENTS",
        "Campos obrigatórios*": "Required fields*", 
        "Email*": "Email*",
        "Informe seu nome*": "Enter your name*",
        "Informe seu e-mail*": "Enter your e-mail*",
        "Informe o assunto*": "Subject*",
        "Escreva sua mensagem...": "Message",
        "ENVIANDO...": "SENDING...",
        "Ver mais": "More detail",
        "DADOS DO PAGAMENTO": "PAYMENT DATA",
        "CATEGORIA / QUARTO": "CATEGORY / ROOM",
        "CATEGORIA / DIÁRIA" : "CATEGORY / DAILY",
        "+ DETALHES": "+ DETAILS",
        "HORA DE PARTIDA DO VOO": "FLIGHT DEPARTURE TIME",
        "HORA DE CHEGADA DO VOO": "FLIGHT ARRIVAL TIME",
        "HORA DO VOO": "FLIGHT TIME",
        "Cidade*": "City*",
        "Endereço*": "Address*",
        "Estado*": "State*",
        "Bairro*": "Neighborhood*",
        "CEP*": "Zip Code*",
        "Aceito as condições": "I accept the conditions",
        "Clique para Visualizar": "Click to See",
        "Adultos:": "Adults:",
        "Crianças:": "Children:",
        "Selecione uma Data:": "Select a Date:",
        "por adulto:": "per adult",
        "por criança:": "per kid",
        "Informe o passageiro": "Inform the passenger",
        "Informe os passageiro para este traslado.": "Inform passengers for this transfer.",
        "Informe os passageiro para este hotel.": "Inform passengers for this hotel.",
        "Categoria": "Category",
        "pessoa(s)": "people",
        "Valor Diária": "Daily Value",
        "Selecione um passageiro existente:" : "Select an existing passenger:",
        "Transferência / pix": "Transfer / pix",
        "Transferência Bancária": "Bank Transfer",
        "PAGAMENTO": "PAYMENT",
        "TRASLADOS": "SHUTTLES",
        "Informe os dados da chegada": "Arrival information",
        "Informe os dados da partida": "Departure information",
        "Cartão de Crédito": "Credit Card",
        "nome*": "name*",
        "VOLTAR": "RETURN",
        "por adulto": "per adult",
        "por criança": "per child",
        "Passaporte": "Passaport",
        "NÚMERO DO VÔO": "FLIGHT NUMBER",
        "Valor por pessoa": "Value per person",
        "Data Nascimento:": "Birthday:",
        "Data de Chegada:": "Arrival date:",
        "Data de Partida:": "Departure date:"
      },
      "es":{
        "Data de Chegada:": "Fecha de llegada:",
        "Data de Partida:": "Fecha de salida:",  
        "DESTINOS": "DESTINOS",
        "MONTE SEU PACOTE": "MONTA TU PAQUETE",
        "INFORMAÇÕES ESSENCIAIS": "INFORMACIÓN ESENCIAL",
        "CONTATO": "CONTACTO",
        "Português": "Portugués",
        "Inglês": "Inglés",
        "Saúde": "Salud",
        "Segurança": "Seguridad",
        "Visto e Documentação": "Visa y Documentación",
        "Números de Emergência": "Números de emergencia",
        "Força e Eletricidade": "Energía y Electricidad",
        "Clima": "Clima",
        "Telecomunicações": "Telecomunicaciones",
        "Idioma": "Idioma",
        "Links Úteis": "Enlaces útiles",
        "Telefone": "Teléfono",
        "Enviar": "Mandar",
        "ENVIAR": "MANDAR",
        "nome": "nombre",
        "telefone": "teléfono",
        "Hospedagem": "Alojamiento",
        "hospedagem": "alojamiento",
        "Aéreo": "Aire",
        "Passeios": "Excursiones",
        "PASSEIOS": "EXCURSIONES",
        "Monte seu pacote": "Arma tu paquete",
        "Enviando": "Enviando",
        "Nos envie uma mensagem": "Mandanos un mensaje",
        "Nome": "Nombre",
        "Assunto": "Tema en cuestion",
        "assunto": "Tema en cuestion",
        "Mensagem": "Mensaje",
        "Contato": "Contacto",
        "contato": "contacto",
        "Buscando destinos": "Buscando destinos",
        "Atrações": "Atracciones",
        "Imperdíveis": "Imperdible",
        "pacote": "paquete",
        "Monte seu": "Monta tu",
        "destinos": "destinos",
        "Contate a": "Contacto",
        "Brocker Eventos": "Brocker Events",
        "e-mail@email.com.br": "Ingrese su dirección de correo electrónico.",
        "Experiências":"Experiencias",
        "o-evento": "el-evento",
        "Veja sobre o Evento": "Ver sobre el evento",
        "O EVENTO": "EL EVENTO",
        "HOSPEDAGEM E TURISMO": "ALOJAMIENTO Y TURISMO",
        "Em breve teremos informações.": "Pronto tendremos información.",
        "dias": "dias",
        "+ DETALHES DO HOTEL": "+ DETALLES DEL HOTEL",
        "HOTEL/CATEGORIA": "HOTEL/CATEGORÍA",
        "QUARTO/TOTAL": "HABITACIÓN/TOTAL",
        "FECHAR": "CERCA",
        "Selecione": "Seleccione",
        "SELECIONAR": "Seleccione",
        "SELECIONADO": "Seleccionado",
        "Próximo": "Próximo",
        "PRÓXIMO": "PRÓXIMO",
        "Voltar": "Vuelve",
        "Finalizar Pedido": "Verifcar",
        "Nome*": "Nombre*",
        "telefone*": "teléfono*", 
        "nome impresso no cartão de crédito*": "nombre impreso en la tarjeta de crédito*",
        "número do cartão de crédito*": "número de tarjeta de crédito*",
        "validade do cartão*": "fecha de caducidad de la tarjeta*",
        "código de segurança*": "código de seguridad*",
        "número de parcelas*": "número de plazos*",
        "Diária:": "Diariamente:",
        "dia": "día",
        "ALTERAR": "CAMBIO",
        "Traslado": "Transferir",
        "por veículo": "por vehículo",
        "por pessoa": "por persona",
        "PASSEIOS E PASSAGENS AÉREAS": "TOURS Y BILLETES AÉREOS",
        "PASSEIOS/INGRESSOS": "TOURS/ENTRADAS",
        "PASSAGENS AÉREAS": "PASAJES AÉREOS",
        "TOTAL DO PAGAMENTO": "PAGO TOTAL",
        "Bandeira": "Bandera",
        "CÓDIGO VOO": "CÓDIGO DE VUELO",
        "DATA": "FECHA",
        "HORA": "HOra",
        "Qualquer dúvida, entre em contato direto:": "Cualquier pregunta, por favor contáctenos directamente.:",
        "Gostaria de receber informações sobre passagens aéreas para Porto Alegre ou PASSEIOS E passeios, ingressos com o melhor conforto e custo benefício para você." : "Deseo recibir información sobre pasajes aéreos a Porto Alegre o TOURS Y TOURS, pasajes con la mejor comodidad y costo beneficio para usted.",
        "HOSPEDAGEM": "HOSPEDAJE",
        "Distância do evento": "Distancia del evento",
        "A partir de:": "De:",
        "COMPRAR": "COMPRA",
        "Enviando os dados. Aguarde!": "Enviando los datos. ¡Espera!",
        "CONSULTA DE EXPERIÊNCIA": "CONSULTA DE EXPERIENCIA",
        "CONSULTE": "CONSULTAR",
        "SOBRE O EVENTO": "SOBRE EL EVENTO",
        "VALORES": "VALORES",
        "DADOS ENVIADOS COM SUCESSO": "DATOS ENVIADOS CON ÉXITO",
        "Recebemos os seus dados. Em breve entraremos em contato!": "Hemos recibido tus datos. ¡Pronto nos contactaremos contigo!",
        "Não temos serviços para mostrar.": "No tenemos servicios para mostrar.",
        "ENCONTRE SEU EVENTO IDEAL": "ENCUENTRA TU EVENTO IDEAL",
        "CLIQUE AQUI": "HAGA CLIC AQUÍ",
        "VER MAIS EVENTOS": "VER MAS EVENTOS",
        "Queremos te ajudar. O que você procura?": "Queremos ayudarte. ¿Qué estás buscando?",
        "EVENTOS": "EVENTOS",
        "Campos obrigatórios*": "Campos obligatorios*", 
        "Email*": "Correo electrónico*",
        "email*": "Correo electrónico*",
        "Informe seu nome*": "Enter your name*",
        "Informe seu e-mail*": "Ingrese su nombre*",
        "Informe o assunto*": "Ingrese el asunto*",
        "Escreva sua mensagem...": "Escribe tu mensaje...",
        "ENVIANDO...": "ENVIANDO...",
        "Ver mais": "Ver más",
        "DADOS DO PAGAMENTO": "DETALLES DEL PAGO",
        "CATEGORIA / QUARTO": "CATEGORÍA / HABITACIÓN",
        "CATEGORIA / DIÁRIA" : "CATEGORÍA / DIARIO",
        "+ DETALHES": "+ DETALLES",
        "HORA DE PARTIDA DO VOO": "HORA DE SALIDA DEL VUELO",
        "HORA DE CHEGADA DO VOO": "HORA DE LLEGADA DEL VUELO",
        "HORA DO VOO": "HORA DE VUELO",
        "Cidade*": "Ciudad",
        "Endereço*": "Dirección",
        "Estado*": "Estado*",
        "Bairro*": "Barrio*",
        "CEP*": "Código postal*",
        "Aceito as condições": "Acepto las condiciones",
        "Clique para Visualizar": "Clic para ver",
        "Adultos:": "Adultos:",
        "Crianças:": "Niños:",
        "Selecione uma Data:": "Seleccione una fecha:",
        "por adulto:": "por adulto:",
        "por criança:": "por niño:",
        "Informe o passageiro": "Informar al pasajero",
        "Informe os passageiro para este traslado.": "Informar a los pasajeros para esta transferencia.",
        "Informe os passageiro para este hotel.": "Informar a los pasajeros de este hotel.",
        "Categoria": "Categoría",
        "pessoa(s)": "persona(s)",
        "Valor Diária": "Valor diario",
        "Selecione um passageiro existente:" : "Seleccione un pasajero existente:",
        "Transferência / pix": "Transferir/foto",
        "Transferência Bancária": "Transferencia Bancaria",
        "PAGAMENTO": "PAGO",
        "TRASLADOS": "TRASLADOS",
        "Informe os dados da chegada": "Informar la información de llegada",
        "Informe os dados da partida": "Informar los datos de salida",
        "Cartão de Crédito": "tarjeta de crédito",
        "nome*": "nombre*",
        "VOLTAR": "VOLVER",
        "por adulto": "por adulto",
        "por criança": "por niño",
        "Passaporte": "Pasaporte",
        "NÚMERO DO VÔO": "NÚMERO DE VUELO",
        "Valor por pessoa": "Valor por persona",
        "Data Nascimento:": "Cumpleaños:",
        "EXCLUIR": "BORRAR",
        "Titular da Reserva": "Titular de la reserva"  
      }
    }


  }

}
